<template>
  <div class="block without-footer current-level">
    <div class="header"><span>現在水位</span><span>{{ last_update }}</span></div>
    <div class="body" > 
        <canvas ref="canvas"></canvas>
    </div>
</div>
</template>
  
<script>

    export default {
      name: 'BlockCurrentLevel',
      components: {
        
      }
      ,computed: {
        last_update : function(){ 
          const t = new Date(this.item.lastUpdate)
          return '更新 : '+ this.dateFormat(t,'yyyy.MM.dd hh:mm')
        }
      }
      ,
      created() {
        // console.log("created") 
            
        }
      ,
      mounted() {
        // console.log("mounted") 
        window.addEventListener('resize', this.onResize);
        setTimeout( (this.drawCanvas).bind(this), 500);
      }
      ,
      updated() {
        //console.log("updated") 
      }
      ,
      props: {
        item: {
          type: Object,
          default: () => []
        }
      },
      data() { 
        return {
          
        }
      }
      ,methods : {
        onResize(){
          this.drawCanvas()
        }
        ,drawCanvas(){
          try{
            if( this.$refs.canvas.getContext){
              
              const ww = window.innerWidth
              let fontsize =  80
              if(ww < 405){
                fontsize = 50
              } else if(ww < 525){
                fontsize = 58
              }
                
                
              let ctx = this.$refs.canvas.getContext('2d');
              const area = this.$refs.canvas.getBoundingClientRect()

              this.$refs.canvas.width= area.width * 2
              this.$refs.canvas.height= area.height * 2
              const left_x = area.width * 2 * 0.15
              const left_w = area.width * 2 * 0.4
              const left_box_y = area.height * 2 * 0.06
              const left_box_h = area.height * 2 * 0.28
              const left_tank_y = left_box_y + left_box_h + area.height * 2 * 0.1
              const left_tank_h = area.height * 2 * 0.45
              const maximum = Math.max(...this.item.waterlevel.level.map(el => el + this.item.fulllevel).concat([this.item.fulllevel]).concat(this.item.alertThresholds.map(el => el + this.item.fulllevel)));
              const tank_bottom_level = 0    // TODO: have to refer to $store data
              const tank_zero_level = this.item.fulllevel;     // TODO: have to refer to $store data
              const tank_full_level = maximum;
              const angle = 7
              const radian = angle * (Math.PI / 180);
              const right_x = area.width * 2 * 0.65
              const right_w = area.width * 2 * 0.32
              const right_box_h = area.height * 2 * 0.17
              let right_y = area.height * 2 * 0.05
              const props = this.$store.getters.getColors
              
              // draw the ground
              ctx.beginPath();
              ctx.fillStyle = '#E3E3E3';
              ctx.fillRect(0, left_tank_y, area.width*2, area.height*2);

              
              // draw the tank white border
              const tank_y_top = left_tank_y
              const tank_x_top = 0;
              const tank_y_bottom = left_tank_y + left_tank_h
              const tank_x_bottom = tank_y_bottom * Math.tan(radian);

              ctx.beginPath();
              ctx.lineWidth = 30 ;
              ctx.moveTo(left_x + tank_x_top + left_w , tank_y_top);
              ctx.lineTo(left_x + tank_x_bottom + left_w - tank_x_bottom*2, tank_y_bottom);
              ctx.lineTo(left_x + tank_x_bottom, tank_y_bottom);
              ctx.lineTo(left_x + tank_x_top, tank_y_top);
              ctx.closePath();
              ctx.fillStyle = '#ffffff';
              ctx.strokeStyle = '#ffffff';
              ctx.fill() ;
              ctx.stroke() ;

              // draw the water
              const topratio = 1-( (this.item.waterlevel.level[this.item.waterlevel.level.length-1] + this.item.fulllevel - tank_bottom_level) / (tank_full_level - tank_bottom_level) )
              const water_y_top = topratio * left_tank_h
              const water_x_top = water_y_top * Math.tan(radian) * 1.8; // wtf 1.8 is
              
              ctx.beginPath();
              ctx.moveTo(left_x + water_x_top, tank_y_top + water_y_top);
              ctx.lineTo(left_x + water_x_top + left_w - water_x_top*2, tank_y_top + water_y_top);
              ctx.lineTo(left_x + tank_x_bottom + left_w - tank_x_bottom*2, tank_y_bottom);
              ctx.lineTo(left_x + tank_x_bottom, tank_y_bottom);
              ctx.closePath();
              ctx.fillStyle = '#71C0FC';
              ctx.fill();
              
              // draw the arrow
              ctx.beginPath();
              ctx.moveTo( left_x + water_x_top - 60, tank_y_top + water_y_top - 30);
              ctx.lineTo( left_x + water_x_top - 60, tank_y_top + water_y_top + 30);
              ctx.lineTo( left_x + water_x_top , tank_y_top + water_y_top );
              ctx.closePath();
              ctx.fillStyle = this.$store.getters.getAlertColor(this.item).color;
              ctx.fill();

              // draw the arrow lines
              ctx.beginPath();
              ctx.moveTo( left_x + water_x_top - 30, tank_y_top + water_y_top );
              ctx.lineTo( left_x/3 , tank_y_top + water_y_top );
              ctx.lineTo( left_x/3 , left_box_y + left_box_h/2 );
              ctx.lineTo( left_x , left_box_y + left_box_h/2 );
              ctx.lineWidth = 20 ;
              ctx.strokeStyle = this.$store.getters.getAlertColor(this.item).color;
              ctx.stroke();
              
              // draw the left top box
              ctx.strokeRect(left_x, left_box_y, left_w, left_box_h);
              ctx.font = "bold " + fontsize*0.625 + "px sans-serif";
              ctx.textAlign = "center";
              ctx.fillStyle = "#000"
              ctx.fillText( this.$store.getters.getAlertColor(this.item).name , left_x + left_w/2, left_box_y + left_box_h*0.36 );
              ctx.font = "bold " + fontsize + "px sans-serif";
              ctx.fillText( this.waterLevelFormat(this.item.waterlevel.level[this.item.waterlevel.level.length-1]) , left_x + left_w/2, left_box_y + left_box_h*0.85 );

              for(let i=5; i > 1; i--){
                
                // draw water level lines
                const the_level = this.item.alertThresholds[i-2] + this.item.fulllevel;
                const ratio = 1-( (the_level - tank_bottom_level) / (tank_full_level - tank_bottom_level) )
                const tank_y = ratio * left_tank_h
                const tank_x = tank_y * Math.tan(radian);

                ctx.beginPath () ;
                ctx.strokeStyle = props[i].color;
                ctx.lineWidth = 6 ;
                ctx.moveTo( left_x + tank_x*2, left_tank_y + tank_y ) ;
                ctx.lineTo( left_x + left_w - (tank_x * 2), left_tank_y + tank_y ) ;
                ctx.setLineDash([20,10]);
                // ctx.closePath();
                ctx.stroke() ;
                
                ctx.beginPath () ;
                ctx.setLineDash([]);
                ctx.lineWidth = 4 ;
                ctx.moveTo( left_x + left_w - (tank_x * 2), left_tank_y + tank_y ) ;
                ctx.lineTo( right_x, right_y + right_box_h/2 ) ;
                ctx.closePath();
                ctx.stroke() ;


                // draw right box
                ctx.fillStyle = props[i].color;
                ctx.fillRect(right_x, right_y, right_w, right_box_h);
                ctx.fillStyle = props[i].text_color;
                ctx.font = "bold " + fontsize*0.5 + "px sans-serif";
                ctx.textAlign = "right";
                if( props[i].name.length > 2){
                  ctx.fillText( props[i].name.substr(0,2), right_x + right_w -10 , right_y + 45);
                  ctx.fillText( props[i].name.substr(2,2), right_x + right_w -10 , right_y + 95);
                } else {
                  ctx.fillText( props[i].name, right_x + right_w -10 , right_y + 70);
                  
                }
                ctx.font = "bold " + fontsize*0.625 + "px sans-serif";
                ctx.textAlign = "left";
                ctx.fillText( "+" + this.waterLevelFormat(the_level - this.item.fulllevel), right_x + 20 , right_y + 75);
                

                right_y += right_box_h + 10
              }

              // draw 常時満水位
              const the_level = tank_zero_level
              const ratio = 1-( (the_level - tank_bottom_level) / (tank_full_level - tank_bottom_level) )
              const tank_y = ratio * left_tank_h
              const tank_x = tank_y * Math.tan(radian);

              ctx.beginPath () ;
              ctx.strokeStyle ='#555555';
              ctx.lineWidth = 4 ;
              ctx.moveTo( left_x + tank_x*2, left_tank_y + tank_y ) ;
              ctx.lineTo( left_x + left_w - (tank_x * 2), left_tank_y + tank_y ) ;
              ctx.setLineDash([20,10]);
              ctx.stroke() ;
              
              ctx.beginPath () ;
              ctx.setLineDash([]);
              ctx.lineWidth = 4 ;
              ctx.moveTo( left_x + left_w - (tank_x * 2), left_tank_y + tank_y ) ;
              ctx.lineTo( right_x, right_y + right_box_h/2 ) ;
              ctx.closePath();
              ctx.stroke() ;

              ctx.fillStyle = '#555555';
              ctx.textAlign = "left";
              ctx.font = "bold 40px sans-serif";
              ctx.fillText( this.waterLevelFormat(0), right_x +10 , right_y + 40);
              ctx.fillText( '常時満水位', right_x  +10, right_y + 90);
            }
            
            


          } catch(e){
              // console.log(e)
          }
         
        }
      }
    }
</script>
<style lang="scss">
.block.current-level { 
  .body { height: 330px; padding:0; overflow: hidden; 
    canvas { height: 100%; width: 100%; min-width: auto;}
  }
}

</style>